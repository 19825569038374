import * as React from "react";
import { ConnectYaml } from "../graphql-types";
import { Card, Container, Image } from "semantic-ui-react";

interface ExtraCardProps {
  banner: string;
  link: string;
  title: string;
}

// CYBOZU SUMMER BLOG FES '24
const extra_content: ExtraCardProps = {
  banner:"../images/blogs/CYBOZU_SUMMER_BLOG_FES_24_banner.png",
  link:"https://cybozu.github.io/summer-blog-fes-2024/",
  title:"CYBOZU SUMMER BLOG FES '24"
};

const ExtraContentsCard: React.FC = () => {
    return (
      <Container cards className="extra-banner">
          <Card key={extra_content.title} link href={extra_content.link} target="_blank" rel="noreferrer noopener">
            <Image src={ extra_content.banner } size="medium" alt={ extra_content.title } title={ extra_content.title } className="connect-card-image"/>
          </Card></Container>
        );
};

export default ExtraContentsCard;
