import * as React from "react";
import { ConnectYaml } from "../graphql-types";
import { Card, Container, Image } from "semantic-ui-react";

interface RSSLinkCardProps {
  banner: string;
  link: string;
  title: string;
}

// サイボウズのTech系RSS feed link
const rss_link: RSSLinkCardProps = {
  banner:"../images/blogs/rss-sabo.png",
  link:"https://cybozu.github.io/tech-blog-rss-feed/",
  title:"サイボウズのTech系ブログのRSSまとめ"
};

const RSSSabo: React.FC = () => {
    return (
      <Container cards className="extra-banner">
          <Card key={rss_link.title} link href={rss_link.link} target="_blank" rel="noreferrer noopener">
            <Card.Content>
              <Image floated="left" size="mini" src={rss_link.banner} alt={`Icon: ${rss_link.banner}`} />
              <Card.Header>{rss_link.title}</Card.Header>
            </Card.Content>
          </Card>
          </Container>
        );
};

export default RSSSabo;
