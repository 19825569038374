import * as React from "react";
import { Item } from "semantic-ui-react";
import { FeedCybozuTechBlog } from "../graphql-types";

interface FeedElement {
  node: FeedCybozuTechBlog;
}

const CybozuTechBlogFeedList: React.FC<Array<FeedElement>> = ({ feeds }) => {
  return (
    <Item.Group link divided relaxed="very">
      {feeds.map((element: FeedElement) => {
      const { id, title, link, isoDate, content, enclosure } = element.node;
      const date = new Date(isoDate);
      const disptitle = title.split('|')[0];
      return (
        <Item key={id} href={link} target="_blank" rel="noreferrer noopener">
          <Item.Image className="mobile hidden" size="small" src={enclosure.url}></Item.Image>
          <Item.Content>
            <Item.Header>{disptitle}</Item.Header>
            <Item.Description>{content.encoded}</Item.Description>
          </Item.Content>
        </Item>
      );
    })}
    </Item.Group>
  );
};

export default CybozuTechBlogFeedList;
